import React from 'react'

function Privacy() {
    return (
        <div>
            <section className="banner_area">
                <div className="banner_inner d-flex align-items-center">
                    <div className="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background>
                    </div>
                    <div className="container">
                        <div className="banner_content text-center">
                            <h2>Privacy Policy</h2>
                            <div className="page_link">
                                <a href="/">Home</a>
                                <a href="javascript:void(0)">Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sample-text-area">
                <div className="container">

                    <h3 className="text-heading title_color">Introduction</h3>
                    <p className="sample-text mb-20">
                        This Privacy Policy describes how Aditya Astrology App ("we," "us," or "our") collects, uses, and discloses your personal information when you use our mobile application (the "App").
                    </p>

                    <h3 className="text-heading title_color">Information We Collect</h3>
                    <p className="sample-text">
                        We collect the following types of information when you use the App:
                    </p>
                    <ul className="unordered-list mb-30">
                        <li><b>Personal Information:</b> This may include your name, date of birth, time of birth, birthplace, and optionally, email address. This information is necessary to generate your astrological chart and provide you with personalized readings.</li>
                        <li><b>Usage Data:</b> We may collect information about how you use the App, such as the features you access, the frequency of your use, and the device you use. This information is used to improve the App and provide a better user experience.</li>
                    </ul>
                    <h3 className="text-heading title_color">How We Use Your Information</h3>
                    <p className="sample-text">
                        We use your information for the following purposes:
                    </p>
                    <ul className="unordered-list mb-30">
                        <li>To generate your astrological chart and provide you with personalized readings within the Aditya Astrology App.</li>
                        <li>To improve the App and provide a better user experience.</li>
                        <li>To respond to your inquiries and requests.</li>
                        <li>To send you marketing communications about the Aditya Astrology App (with your consent).</li>
                    </ul>

                    <h3 className="text-heading title_color">Sharing Your Information</h3>
                    <p className="sample-text">
                        We will not share your personal information with third parties without your consent. However, we may share your usage data with third-party analytics providers to help us improve the Aditya Astrology App. These providers are obligated to keep your information confidential.
                    </p>
                    <p className="sample-text">We may also disclose your information if required by law or to protect the rights and safety of ourselves or others.</p>

                    <h3 className="text-heading title_color">Your Choices</h3>
                    <p className="sample-text">
                        You can choose not to provide certain personal information. However, this may limit your ability to use certain features of the Aditya Astrology App, such as generating personalized readings.
                    </p>
                    <p className="sample-text">
                        You can also opt out of receiving marketing communications from us by following the unsubscribe instructions in the communications.
                    </p>

                    <h3 className="text-heading title_color">Data Security</h3>
                    <p className="sample-text">
                        We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no internet transmission is completely secure, and we cannot guarantee the security of your information.
                    </p>

                    <h3 className="text-heading title_color">Children's Privacy</h3>
                    <p className="sample-text">
                        Our Aditya Astrology App is not directed to children under the age of [age requirement in your region]. We do not knowingly collect personal information from children under [age requirement in your region]. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us at [your email address]. We will take steps to remove the information from our servers.
                    </p>

                    <h3 className="text-heading title_color">Changes to this Privacy Policy</h3>
                    <p className="sample-text">
                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Aditya Astrology App.
                    </p>

                    <h3 className="text-heading title_color">Contact Us</h3>
                    <p className="sample-text">
                        If you have any questions about this Privacy Policy, please contact us at [your email address].
                    </p>

                    <h3 className="text-heading title_color">Additional Considerations</h3>
                    <ul className="unordered-list mb-30">
                        <li>You may want to add a section about data retention, specifying how long you keep user information</li>
                        <li>Consider including a section about your legal basis for collecting and processing data, especially if you operate in regions with strict data privacy regulations (e.g., GDPR).</li>
                        <li>You may also want to provide a mechanism for users to access and update their personal information.</li>
                    </ul>

                </div>
            </section>
        </div>
    )
}

export default Privacy

import React, { useEffect, useState } from 'react';

function Home() {
    const [navOffsetTop, setNavOffsetTop] = useState(0);

    useEffect(() => {
        const headerHeight = document.getElementById('header_area').offsetHeight;
        setNavOffsetTop(headerHeight + 50);

        const handleScroll = () => {
            const scroll = window.scrollY;
            const headerArea = document.querySelector('.header_area');

            if (scroll >= navOffsetTop) {
                headerArea.classList.add('navbar_fixed');
            } else {
                headerArea.classList.remove('navbar_fixed');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [navOffsetTop]);
   

    return (
        <div>
            {/* Banner */}
            <section className="home_banner_area" id="home">
                <div className="banner_inner">
                    <div className="container">
                        <div className="row banner_content">
                            <div className="col-lg-9">
                                <h2>ADITYA</h2>
                                <p>Udupi is the sacred land of Lord Shri Krishna . Udupi is the land of great philosopher Jagadguru Shri
                                    Madhvacharya .</p>
                                <p>Udupi is the land of many scholors who fortified Indian philosophy . Udupi was the inspiration of several
                                    poets wellknown as Dasa such as Purandara Dasaru and KanakaDasaru .</p>
                                <p>Udupi is and was the base for the study of every vedic sciences . </p>

                            </div>
                            <div className="col-lg-3">
                                <div className="banner_map_img">
                                    <img className="img-fluid" src="assets/banner/right-mobile.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Features */}
            <section className="feature_area p_120" id="feature">
                <div className="container">
                    <div className="main_title">
                        <h2>Unique Features</h2>
                        <p>We in udupiastrology trying to give the best solution for astrologers . We developed the advance app to
                            generate horoscope and Prashna Kundali , which is available in both Android and IOS .</p>
                    </div>
                    <div className="feature_inner row">
                        <div className="col-lg-3 col-md-6">
                            <div className="feature_item">
                                <img src="assets/icon/horoscope.webp" alt="" />
                                <h4>Horoscope </h4>
                                <p>In astrology, a horoscope is used to analyze and interpret the influence of celestial bodies on an
                                    individual's personality, behavior, strengths, weaknesses, and life events. It is believed that the
                                    positions of the planets at the time of birth can have a significant impact on various aspects of a
                                    person's life, including career, relationships, health, and overall destiny.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature_item">
                                <img src="assets/icon/prashna.jpg" alt="" />
                                <h4>Prashna Kundali</h4>
                                <p>The Prashna Kundali provides insights into the current situation and potential outcomes related to the
                                    question asked. It considers the positions of celestial bodies at the time of inquiry, along with their
                                    influences and alignments. Astrologers interpret the Prashna Kundali to offer guidance, predictions, and
                                    solutions related to the specific query.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature_item">
                                <img src="assets/icon/navamsa.webp" alt="" />
                                <h4>Navamsa Kundli</h4>
                                <p>The Navamsa chart divides each sign of the Zodiac into nine equal parts, resulting in a chart with twelve
                                    signs and nine divisions each, totaling 108 divisions. Each division is known as a Navamsa, and they
                                    represent specific aspects of a person's life, particularly focusing on marriage, spiritual growth, and
                                    overall well-being.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature_item">
                                <img src="assets/icon/bhava.webp" alt="" />
                                <h4>Bhava Kundali</h4>
                                <p>In Vedic astrology, the Bhava Kundali is composed of twelve houses, or Bhavas, which represent different
                                    aspects of an individual's life, such as health, wealth, career, relationships, etc. Each house is
                                    associated with specific areas of life and can provide insights into various aspects of one's personality,
                                    strengths, weaknesses, and life events.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Special1 */}
            <section className="interior_area">
                <div className="container">
                    <div className="interior_inner row">
                        <div className="col-lg-6">
                            <img className="img-fluid" src="assets/interior-1.png" alt="" />
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="interior_text">
                                <h4>We Believe that Interior beautifies the Total Architecture</h4>
                                <p>Vedas have six elements which called as Shadangas .Astrology is considered as Vedanga or one of the
                                    elements of Vedas. </p>
                                {/* <a className="main_btn" href="javascript:void(0)">See Details</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Special 2 */}
            <section className="interior_area interior_two">
                <div className="container">
                    <div className="interior_inner row">
                        <div className="col-lg-5 offset-lg-1">
                            <div className="interior_text">
                                <h4>We Believe that Interior beautifies the Total Architecture</h4>
                                <p>Astrology has mainly 3 divisions such as Ganitha ,Samhitha and Hora . Ganitha part consists of
                                    calculation part which helps in deciding the positions of planets .</p>
                                <a className="main_btn" href="#">See Details</a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid" src="assets/interior-2.png" alt="" />
                        </div>
                    </div>
                    <div className="video_area" id="video">
                        <img className="img-fluid" src="assets/video-1.png" alt="" />
                        <a className="popup-youtube" href="https://www.youtube.com/watch?v=VufDd-QL1c0">
                            <img src="assets/icon/video-icon-1.png" alt="" />
                        </a>
                    </div>
                </div>
            </section>
            {/* Price */}
            <section className="price_area p_120" id="price">
                <div className="container">
                    <div className="main_title">
                        <h2>Pricing Table</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.</p>
                    </div>
                    <div className="price_item_inner row">
                        <div className="col-lg-4 offset-lg-2">
                            <div className="price_item">
                                <div className="price_head">
                                    <div className="float-left">
                                        <h3>Standard</h3>
                                        <p>For the individuals</p>
                                    </div>
                                    <div className="float-right">
                                        <h2>Free</h2>
                                    </div>
                                </div>
                                <div className="price_body">
                                    <ul className="list">
                                        <li><a href="#">Basic Details</a></li>
                                        <li><a href="#">Rashi Kundli</a></li>
                                        <li><a href="#">Navamsha Kundli</a></li>
                                        <li><a href="#">Panchanga</a></li>
                                        <li><a href="#">Unlimited Profiles</a></li>
                                    </ul>
                                </div>
                                <div className="price_footer">
                                    <a className="main_btn2" href="#">Download Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="price_item premium">
                                <div className="price_head">
                                    <div className="float-left">
                                        <h3>Premium</h3>
                                        <p>For the individuals</p>
                                    </div>
                                    <div className="float-right">
                                        <h2>₹499/Yr</h2>
                                    </div>
                                </div>
                                <div className="price_body">
                                    <ul className="list">
                                        <li><a href="#">Dasha Bhukti</a></li>
                                        <li><a href="#">Shadvarga</a></li>
                                        <li><a href="#">Ashtakavarga</a></li>
                                        <li><a href="#">Trisphutadi</a></li>
                                        <li><a href="#">Dhoomadi</a></li>
                                    </ul>
                                </div>
                                <div className="price_footer">
                                    <a className="main_btn2" href="#">Download Now</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* Screenshot */}
            <section className="screenshot_area p_120" id="screen">
                <div className="container">
                    <div className="main_title">
                        <h2>Unique Screenshots</h2>
                        <p>This app calculates the accurate planetary positions and also displays Rasi, Navamsa, Bhava Kundali. We plan to give few more such solutions in future also.</p>
                    </div>
                    <div className="screenshot_inner owl-carousel">
                        <div className="item">
                            <img src="assets/feature/feature-1.jpg" alt="Screenshot 1" />
                        </div>
                        <div className="item">
                            <img src="assets/feature/feature-2.jpg" alt="Screenshot 2" />
                        </div>
                        <div className="item">
                            <img src="assets/feature/feature-3.jpg" alt="Screenshot 3" />
                        </div>
                        <div className="item">
                            <img src="assets/feature/feature-4.jpg" alt="Screenshot 4" />
                        </div>
                        <div className="item">
                            <img src="assets/feature/feature-5.jpg" alt="Screenshot 5" />
                        </div>
                        <div className="item">
                            <img src="assets/feature/feature-6.jpg" alt="Screenshot 6" />
                        </div>
                        <div className="item">
                            <img src="assets/feature/feature-7.jpg" alt="Screenshot 7" />
                        </div>
                        <div className="item">
                            <img src="assets/feature/feature-8.jpg" alt="Screenshot 8" />
                        </div>
                        <div className="item">
                            <img src="assets/feature/feature-9.jpg" alt="Screenshot 9" />
                        </div>
                        <div className="item">
                            <img src="assets/feature/feature-10.jpg" alt="Screenshot 10" />
                        </div>
                        <div className="item">
                            <img src="assets/feature/feature-11.jpg" alt="Screenshot 11" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home

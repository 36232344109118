import React from 'react'

function Header() {
    return (
        <div>
            <header className="header_area" id='header_area'>
                <div className="main_menu" id="mainNav">
                    <nav className="navbar navbar-expand-lg navbar-dark">
                        <div className="container">
                            <a className="navbar-brand logo_h" href="/">
                                <img src="assets/logo_square.svg" alt="Aditya" />
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>

                            <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                                <ul className="nav navbar-nav menu_nav ml-auto">
                                    <li className="nav-item active"><a className="nav-link" href="/">Home</a></li>
                                    <li className="nav-item"><a className="nav-link" href="/#feature">FEATURES</a></li>
                                    <li className="nav-item"><a className="nav-link" href="/#price">PRICING</a></li>
                                    <li className="nav-item"><a className="nav-link" href="/#screen">SCREENS</a></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </div>
    )
}

export default Header

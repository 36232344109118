import React from 'react'
import Home from './pages/Home'
import Privacy from './pages/Privacy'
import Terms from './pages/Terms'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
function App() {
  return (

    <BrowserRouter>
      <div>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route exact path='/privacy-policy' element={<Privacy />} />
          <Route exact path='/terms-and-condition' element={<Terms />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>

  )
}

export default App

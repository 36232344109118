import React from 'react'

function Terms() {
    return (
        <div>
            <section className="banner_area">
                <div className="banner_inner d-flex align-items-center">
                    <div className="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background>
                    </div>
                    <div className="container">
                        <div className="banner_content text-center">
                            <h2>Terms & Condition</h2>
                            <div className="page_link">
                                <a href="/">Home</a>
                                <a href="javascript:void(0)">Terms & Condition</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sample-text-area">
                <div className="container">

                    <h3 className="text-heading title_color">Welcome to Aditya Astrology App!</h3>
                    <p className="sample-text mb-20">
                        These Terms and Conditions ("Terms") govern your use of the Aditya Astrology App ("App"), a mobile application developed and operated by [Your Name/Company Name] ("we," "us," or "our"). By accessing or using the App, you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not access or use the App.
                    </p>

                    <h3 className="text-heading title_color">Use of the App</h3>

                    <ul className="unordered-list mb-30">
                        <li>You must be at least [age requirement in your region] years old to use the App.</li>
                        <li>You are solely responsible for your use of the App and any consequences thereof.</li>
                        <li>You agree to use the App in a lawful and respectful manner and in accordance with all applicable laws and regulations.</li>
                        <li>You agree not to use the App for any unauthorized or illegal purpose.</li>
                        <li>You agree not to interfere with or disrupt the servers or networks connected to the App.</li>
                        <li>You agree not to transmit any viruses or other harmful code through the App.</li>
                    </ul>
                    <h3 className="text-heading title_color">Content</h3>
                    <ul className="unordered-list mb-30">
                        <li>The App contains information, including text, graphics, images, and other materials ("Content"). The Content is protected by copyright, trademark, and other intellectual property laws.</li>
                        <li>You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, or otherwise exploit any Content without our prior written consent.</li>
                    </ul>


                    <h3 className="text-heading title_color">User-Generated Content</h3>
                    <ul className="unordered-list mb-30">
                        <li>The App may allow you to submit your own content ("User-Generated Content"). You retain all ownership rights to your User-Generated Content.</li>
                        <li>By submitting User-Generated Content, you grant us a non-exclusive, royalty-free, worldwide license to use, modify, publish, and translate your User-Generated Content in connection with the App.</li>
                        <li>You are solely responsible for your User-Generated Content and the consequences of submitting it.</li>
                        <li>You agree not to submit any User-Generated Content that is unlawful, offensive, discriminatory, or otherwise violates these Terms.</li>
                    </ul>

                    <h3 className="text-heading title_color">Disclaimers</h3>
                    <ul className="unordered-list mb-30">
                        <li>The App and the Content are provided "as is" and without warranties of any kind, express or implied.</li>
                        <li>We disclaim all warranties, express or implied, including but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
                        <li>We do not warrant that the App will be uninterrupted, secure, or error-free.</li>
                        <li>We do not warrant that the Content is accurate, complete, reliable, current, or error-free.</li>
                        <li>You use the App at your own risk.</li>
                    </ul>


                    <h3 className="text-heading title_color">Limitation of Liability</h3>
                    <ul className="unordered-list mb-30">
                        <li>We will not be liable for any damages arising out of or related to your use of the App or the Content.</li>
                        <li>This includes, but is not limited to, direct, indirect, incidental, consequential, punitive, and special damages.</li>
                    </ul>

                    <h3 className="text-heading title_color">Termination</h3>
                    <ul className="unordered-list mb-30">
                        <li>We may terminate your access to the App at any time, for any reason, or no reason at all, without notice.</li>
                        <li>You may terminate your use of the App at any time.</li>
                    </ul>

                    <h3 className="text-heading title_color">Governing Law</h3>
                    <ul className="unordered-list mb-30">
                        <li>These Terms will be governed by and construed in accordance with the laws of [your jurisdiction], without regard to its conflict of law provisions.</li>
                    </ul>

                    <h3 className="text-heading title_color">Entire Agreement</h3>
                    <ul className="unordered-list mb-30">
                        <li>These Terms constitute the entire agreement between you and us regarding your use of the App.</li>
                    </ul>

                    <h3 className="text-heading title_color">Changes to the Terms</h3>
                    <ul className="unordered-list mb-30">
                        <li>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on the App.</li>
                        <li>Your continued use of the App after the posting of the revised Terms constitutes your acceptance of the revised Terms.</li>
                    </ul>

                    <h3 className="text-heading title_color">Contact Us</h3>
                    <ul className="unordered-list mb-30">
                        <li>If you have any questions about these Terms, please contact us at [your email address].</li>
                    </ul>

                    <h3 className="text-heading title_color">Additional Considerations</h3>
                    <ul className="unordered-list mb-30">
                        <li>You may want to add a section about dispute resolution, specifying how to handle any disagreements arising from these Terms.</li>
                        <li>Consider including a severability clause, stating that if any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</li>
                        <li>You may also want to provide a mechanism for users to access and update their personal information (relevant if your app stores user data beyond what's required for astrology readings).</li>
                    </ul>

                </div>
            </section>
        </div>
    )
}

export default Terms

import React from 'react'
import { Link } from 'react-router-dom';
function Footer() {
    return (
        <div>
            <footer className="footer-area p_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4  col-md-6 col-sm-6">
                            <div className="single-footer-widget tp_widgets">
                                <h6 className="footer_title">Contact Information</h6>
                                <ul className="list">
                                    <li><a href="mailTo:sgjoisa@gmail.com"><i className="fa fa-envelope"></i> sgjoisa@gmail.com</a></li>
                                    <li><a href="tel:+919945016110"><i className="fa fa-phone"></i> +919945016110</a></li>
                                    <li><a href="#"><i className="fa fa-map"></i> Udupi</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-footer-widget tp_widgets">
                                <h6 className="footer_title">Important Links</h6>
                                <ul className="list">
                                    <li><a href="index.html">Home</a></li>
                                    <li><a href="index.html#feature">Features</a></li>
                                    <li><a href="index.html#price">Pricing</a></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/terms-and-condition">Terms & Conditions</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-footer-widget instafeed">
                                <h6 className="footer_title">Social Share</h6>
                                <ul className="list instafeed d-flex flex-wrap">
                                    <li><a href="#" target='_blank'><img src="assets/instagram/facebook.png" alt="" /></a></li>
                                    <li><a href="#" target='_blank'><img src="assets/instagram/twitter.png" alt="" /></a></li>
                                    <li><a href="#" target='_blank'><img src="assets/instagram/instagram.png" alt="" /></a></li>
                                    <li><a href="#" target='_blank'><img src="assets/instagram/youtube.png" alt="" /></a></li>
                                    <li><a href="#" target='_blank'><img src="assets/instagram/linkedin-logo.png" alt="" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row footer-bottom d-flex justify-content-between align-items-center">
                        <p className="col-lg-8 col-md-8 footer-text m-0">
                            Copyright &copy;
                            <script>document.write(new Date().getFullYear());</script> All rights reserved <i
                                className="fa fa-heart-o" aria-hidden="true"></i> by <a href="https://ampwork.com/" target="_blank">AMPWORK</a>
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
